@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotate360CounterClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotate360Clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-360-ccw {
  animation: rotate360CounterClockwise 0.3s linear;
}

.rotate-360-cw {
  animation: rotate360Clockwise 0.3s linear;
}

.sync-icon svg path {
  fill: #737373; /* Default color for light mode */
}

.sync-icon.dark svg path {
  fill: #FFFFFF; /* Color for dark mode */
}